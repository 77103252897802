// redux
import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { isRejectedWithValue } from '@reduxjs/toolkit';
//
import { notify } from "@fivewest/component-library";
import { tradingApi, marketsApi } from '@fivewest/frontend-api-library';

export const marketsapi = marketsApi.marketsApi

export const tradingapi = tradingApi.tradingApi.enhanceEndpoints({
  addTagTypes: ['Bot', 'Deployment'],
  endpoints: {
    getAllApiV1BotsGet: {
      providesTags: ['Bot'],
    },
    getApiV1BotsBotIdentifierGet: {
      providesTags: ['Bot'],
    },
    deploymentsApiV1BotsDeploymentsGet: {
      providesTags: ['Deployment'],
    },
    addOrUpdateApiV1BotsBotIdentifierPost: {
      invalidatesTags: ['Bot'],
    },
    startApiV1BotsStartPost: {
      invalidatesTags: ['Deployment'],
    },
    stopApiV1BotsStopPost: {
      invalidatesTags: ['Deployment'],
    }
  },
});

export type BotModel = tradingApi.BotModel;
export type BotStatus = tradingApi.BotStatus;
export type BotPost = tradingApi.BotPost;
export type AddUpdateBotArg = tradingApi.AddOrUpdateApiV1BotsBotIdentifierPostApiArg
export type Trade = tradingApi.TradeModel;
export type Balance = tradingApi.Balance;

export const {
  useAddOrUpdateApiV1BotsBotIdentifierPostMutation,
  useDeploymentsApiV1BotsDeploymentsGetQuery,
  useGetAllApiV1BotsGetQuery,
  useGetCollectionApiV1FirestoreCollectionGetQuery,
  useGetDocumentApiV1FirestoreDocumentGetQuery,
  useIpAddressesApiV1BotsIpAddressesGetQuery,
  usePostDocumentApiV1FirestoreDocumentPostMutation,
  usePutDocumentApiV1FirestoreDocumentPutMutation,
  useRemoveCollectionApiV1FirestoreCollectionDeleteMutation,
  useRemoveDocumentApiV1FirestoreDocumentDeleteMutation,
  useStartApiV1BotsStartPostMutation,
  useStopApiV1BotsStopPostMutation,
  useGetApiV1BotsBotIdentifierGetQuery,
  useGetApiV1TradesGetQuery,
  useGetLogsApiV1BotsLogsBotIdentifierGetQuery,
  useBinanceApiV1BalancesBinanceGetQuery,
  useGetApiV1SubaccountsGetQuery,
  useCalculateAggregateTradesApiV1TradesAggregateGetQuery
} = tradingapi;

export const {
  useFetchMarketsApiV1RatesMarketsGetQuery,
  useFetchCrossRatesApiV1RatesCrossGetQuery
} = marketsapi;

export const rtkQueryErrorLogger: Middleware =
  (_api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      console.log(action)
      notify(
        action.error.message +
        ' with status code ' +
        action.payload.status +
        ': ' +
        action.payload.data.detail, {variant: 'error'}
      );
    }
    return next(action);
  }
