// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  bots: '/bots',
  trades: '/trades',
  page403: '/403',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  rates: path(ROOTS_DASHBOARD, '/rates'),
  bots: {
    root: path(ROOTS_DASHBOARD, '/bots'),
    new: path(ROOTS_DASHBOARD, '/bots/new')
  },
  trades: {
    root: path(ROOTS_DASHBOARD, '/trades'),
  },
  pnl: path(ROOTS_DASHBOARD, '/pnl'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  comingSoon: path(ROOTS_DASHBOARD, '/coming-soon'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
  },
};

export const PATH_DOCS = 'https://payments.fivewest.app/docs';
