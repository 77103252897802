// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
//
import { Iconify } from "@fivewest/component-library";

// ----------------------------------------------------------------------

const ICONS = {
  rates: <Iconify icon="material-symbols:trending-up" />,
  bots: <Iconify icon="mdi:robot" />,
  trades: <Iconify icon="bi:currency-exchange" />,
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "general",
    items: [
      // RATES
      { title: "rates", path: PATH_DASHBOARD.rates, icon: ICONS.rates },
      // BOTS
      {
        title: "bots",
        roles: ["trader"],
        path: PATH_DASHBOARD.bots.root,
        icon: ICONS.bots,
      },
      // TRADES
      { title: "trades", path: PATH_DASHBOARD.trades.root, icon: ICONS.trades },
    ],
  },
];

export default navConfig;
