// @types
import { Notification, NotificationType } from '../../@types/notification';
// redux
import { createSlice } from '@reduxjs/toolkit';
import { tradingapi } from './api';
//
import { notify } from '@fivewest/component-library';

// ----------------------------------------------------------------------

const initialState: Array<Notification> = [];

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    markAllNotificationsAsRead(state) {
      state = state.map((notification) => {
        return { ...notification, isUnRead: false }
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher( // Create/update bot - Success
        tradingapi.endpoints.addOrUpdateApiV1BotsBotIdentifierPost.matchFulfilled,
        (state, _action) => {
          state.push({
            id: Math.floor(Math.random() * 100),
            title: 'Bot created/updated',
            description: '',
            avatar: null,
            type: 'bot_created_updated' as NotificationType,
            createdAt: new Date(),
            isUnRead: true,
          });
          notify('Successfully created/updated bot');
        }
      )
      .addMatcher( // Start bot - Success
        tradingapi.endpoints.startApiV1BotsStartPost.matchFulfilled,
        (state, _action) => {
          state.push({
            id: Math.floor(Math.random() * 100),
            title: 'Bot started',
            description: '',
            avatar: null,
            type: 'bot_started' as NotificationType,
            createdAt: new Date(),
            isUnRead: true,
          });
          notify('Successfully started bot');
        }
      )
      .addMatcher( // Stop bot - Success
        tradingapi.endpoints.stopApiV1BotsStopPost.matchFulfilled,
        (state, _action) => {
          state.push({
            id: Math.floor(Math.random() * 100),
            title: 'Bot stopped',
            description: '',
            avatar: null,
            type: 'bot_stopped' as NotificationType,
            createdAt: new Date(),
            isUnRead: true,
          });
          notify('Successfully stopped bot');
        }
      )
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { markAllNotificationsAsRead } = slice.actions;

// ----------------------------------------------------------------------
