import {
  AuthGuard,
  GuestGuard,
  LoadingScreen,
  LogoOnlyLayout,
  RoleBasedGuard,
  useAuth,
} from "@fivewest/component-library";
import { ElementType, lazy, Suspense } from "react";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
import { PATH_AFTER_LOGIN } from "../config";
import DashboardLayout from "../layouts/dashboard";

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes("/dashboard") && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard redirectTo="/dashboard">
              <Login />
            </GuestGuard>
          ),
        },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "new-password", element: <NewPassword /> },
        { path: "verify", element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: "/dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "rates", element: <Rates />, index: true },
        {
          path: "bots",
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={["trader"]}>
                  <Bots />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: "new",
              element: (
                <RoleBasedGuard hasContent roles={["trader"]}>
                  <NewBot />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        { path: "trades", element: <Trades />, index: true },
        {
          path: "user",
          children: [
            {
              element: <Navigate to="/dashboard/user/profile" replace />,
              index: true,
            },
            { path: "profile", element: <Profile /> },
            // { path: 'list', element: <UserList /> },
            // { path: 'new', element: <RoleBasedGuard hasContent roles={['merchant', 'admin']}><UserCreateUpdate /></RoleBasedGuard> },
            // { path: ':email/edit', element: <RoleBasedGuard hasContent roles={['merchant', 'admin']}><UserCreateUpdate /></RoleBasedGuard> },
          ],
        },
        {
          path: "coming-soon",
          element: <Navigate to="/coming-soon" replace />,
        },
        { path: "permission-denied", element: <PermissionDenied /> },
      ],
    },

    // Main Routes
    { path: "/", element: <Navigate to="/dashboard" replace /> },
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        {
          path: "coming-soon",
          element: <ComingSoon when={new Date("2022/11/18")} />,
        },
        { path: "maintenance", element: <Maintenance /> },
        { path: "500", element: <Page500 /> },
        { path: "404", element: <Page404 /> },
        { path: "403", element: <Page403 /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(
  lazy(() =>
    import("@fivewest/component-library").then(({ Login }) => ({
      default: Login,
    }))
  )
);
const ResetPassword = Loadable(
  lazy(() =>
    import("@fivewest/component-library").then(({ ResetPassword }) => ({
      default: ResetPassword,
    }))
  )
);
const NewPassword = Loadable(
  lazy(() =>
    import("@fivewest/component-library").then(({ NewPassword }) => ({
      default: NewPassword,
    }))
  )
);
const VerifyCode = Loadable(
  lazy(() =>
    import("@fivewest/component-library").then(({ VerifyCode }) => ({
      default: VerifyCode,
    }))
  )
);

// DASHBOARD

// GENERAL

// Rates
const Rates = Loadable(lazy(() => import("../pages/dashboard/rates")));

// Bots
const Bots = Loadable(lazy(() => import("../pages/dashboard/bots")));
const NewBot = Loadable(lazy(() => import("../pages/dashboard/bots/New")));

// Trades
const Trades = Loadable(
  lazy(() => import("../pages/dashboard/trades"))
);

// MANAGEMENT
// USER
const Profile = Loadable(lazy(() => import("../pages/dashboard/user/Profile")));
// const UserList = Loadable(lazy(() => import('../pages/dashboard/user')));
// const UserCreateUpdate = Loadable(lazy(() => import('../pages/dashboard/user/CreateUpdate')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(
  lazy(() => import("../pages/dashboard/PermissionDenied"))
);

// MAIN
const ComingSoon = Loadable(
  lazy(() =>
    import("@fivewest/component-library").then(({ ComingSoon }) => ({
      default: ComingSoon,
    }))
  )
);
const Maintenance = Loadable(
  lazy(() =>
    import("@fivewest/component-library").then(({ Maintenance }) => ({
      default: Maintenance,
    }))
  )
);
const Page500 = Loadable(
  lazy(() =>
    import("@fivewest/component-library").then(({ Page500 }) => ({
      default: Page500,
    }))
  )
);
const Page403 = Loadable(
  lazy(() =>
    import("@fivewest/component-library").then(({ Page403 }) => ({
      default: Page403,
    }))
  )
);
const Page404 = Loadable(
  lazy(() =>
    import("@fivewest/component-library").then(({ Page404 }) => ({
      default: Page404,
    }))
  )
);
