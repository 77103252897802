// redux
import { createSlice } from "@reduxjs/toolkit";
import dayjs, { Dayjs } from "dayjs";

// ----------------------------------------------------------------------

export type Side = "Buy" | "Sell";

export interface TradesState {
  subaccount: string;
  startDate: Dayjs;
  endDate: Dayjs;
  base: string | undefined
  quote: string | undefined
  side: Side | undefined
  rate: number | undefined
  quoteAmount: number | undefined
  baseAmount: number | undefined
}

const initialState: TradesState = {
  subaccount: "",
  startDate: dayjs(new Date()),
  endDate: dayjs(new Date()),
  base: undefined,
  quote: undefined,
  side: undefined,
  rate: undefined,
  quoteAmount: undefined,
  baseAmount: undefined
};

const slice = createSlice({
  name: "trades",
  initialState,
  reducers: {
    setSubaccount: (state, { payload: { subaccount } }) => {
      state.subaccount = subaccount;
    },
    setBase: (state, { payload: { base } }) => {
      state.base = base;
    },
    setQuote: (state, { payload: { quote } }) => {
      state.quote = quote;
    },
    setSide: (state, { payload: { side } }) => {
      state.side = side;
    },
    setRate: (state, { payload: { rate } }) => {
      state.rate = rate;
    },
    setQuoteAmount: (state, { payload: { quoteAmount } }) => {
      state.quoteAmount = quoteAmount;
    },
    setBaseAmount: (state, { payload: { baseAmount } }) => {
      state.baseAmount = baseAmount;
    },
    setStartDate: (state, { payload: { startDate } }) => {
      state.startDate = startDate;
    },
    setEndDate: (state, { payload: { endDate } }) => {
      state.endDate = endDate;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setSubaccount, setBase, setQuote, setSide, setRate, setQuoteAmount, setBaseAmount, setStartDate, setEndDate } = slice.actions;

// ----------------------------------------------------------------------
