import { noCase } from 'change-case';
import { Fragment, useState } from 'react';
// @mui
import {
  Avatar, Badge, Box, Button, Divider,
  IconButton, List, ListItemAvatar,
  ListItemButton, ListItemText,
  ListSubheader, Tooltip, Typography
} from '@mui/material';
import { alpha } from '@mui/material/styles';
// @types
import { Notification, notificationIcons } from '../../../@types/notification';
// redux
import { markAllNotificationsAsRead } from '../../../redux/slices/notifications';
import { dispatch, useSelector } from '../../../redux/store';
//
import { fToNow, IconButtonAnimate, Iconify, MenuPopover, Scrollbar } from '@fivewest/component-library';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const notifications = useSelector<Array<Notification>>((state) => state.notifications);

  const totalUnRead = notifications.filter((item) => item.isUnRead === true).length;

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = () => {
    dispatch(markAllNotificationsAsRead())
  };

  return (
    <>
      <Tooltip title="Notifications" placement="left">
        <Fragment>
          <IconButtonAnimate
            color={open ? 'primary' : 'default'}
            onClick={handleOpen}
            sx={{
              p: 1.25,
              transition: (theme) => theme.transitions.create('all'),
              '&:hover': {
                color: 'primary.main',
                bgcolor: (theme) =>
                  alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
              },
            }}
          >
            <Badge badgeContent={totalUnRead} color="error">
              <Iconify icon="eva:bell-fill" width={20} height={20} />
            </Badge>
          </IconButtonAnimate>
        </Fragment>
      </Tooltip>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <Fragment>
                <IconButton color="primary" onClick={handleMarkAllAsRead}>
                  <Iconify icon="eva:done-all-fill" width={20} height={20} />
                </IconButton>
              </Fragment>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                New
              </ListSubheader>
            }
          >
            {notifications.slice(0, 2).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List>

          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Before that
              </ListSubheader>
            }
          >
            {notifications.slice(2, 5).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            View All
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

function NotificationItem({ notification }: { notification: Notification }) {
  const { avatar, title } = renderContent(notification);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.createdAt)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification: Notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(notification.description)}
      </Typography>
    </Typography>
  );

  if (notification.type) {
    return {avatar: <Iconify icon={notificationIcons[notification.type]} width={20} height={20} />, title};
  } else {
    return {avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null, title};
  }
}
