import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
// slices
import notificationReducer from "./slices/notifications";
import tradesReducer from "./slices/trades";
import { tradingapi, marketsapi } from "./slices/api";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const rootReducer = combineReducers({
  [tradingapi.reducerPath]: tradingapi.reducer,
  [marketsapi.reducerPath]: marketsapi.reducer,
  notifications: notificationReducer,
  trades: tradesReducer,
});

export { rootPersistConfig, rootReducer };
