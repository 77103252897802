// routes
import Router from "./routes";
//
import {
  ChartStyle,
  ErrorBoundary,
  MotionLazyContainer,
  NotistackProvider,
  ProgressBarStyle,
  ScrollToTop,
  ThemeProvider,
  ThemeSettings
} from "@fivewest/component-library";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <NotistackProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <ErrorBoundary>
                <ProgressBarStyle />
                <ChartStyle />
                <ScrollToTop />
                <Router />
              </ErrorBoundary>
            </LocalizationProvider>
          </NotistackProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
