// ----------------------------------------------------------------------

export type NotificationType = 
  | 'bot_created_updated'
  | 'bot_started'
  | 'bot_stopped';

export type Notification = {
  id: number;
  title: string;
  description: string;
  avatar: string | null;
  type: NotificationType;
  createdAt: Date;
  isUnRead: boolean;
}

export const notificationIcons = {
  bot_created_updated: 'mdi:robot',
  bot_started: 'mdi:robot',
  bot_stopped: 'mdi:robot-dead',
}
